import { PlainMessage } from "@bufbuild/protobuf";
import {
  Bundle,
  Event,
  EventSeries,
  Price,
  Section,
} from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { Image_Variant } from "@egocentric-systems/ts-apis/booking_gateway/v1/types_pb";
import { getDictionary } from "./dictionary";
import {
  DiscountConfig,
  SeatUpgrade,
  SeatUpgradeConfig,
} from "@egocentric-systems/ts-apis/booking/types/v1/order_pb";
import { Money } from "@egocentric-systems/ts-apis/types/v1/money_pb";

export const UNLOCK_CODE_COOKIE = "unlockCode";
export const UNLOCK_CODE_COOKIE_MAX_AGE = 0.125; // in days
export const CART_ITEMS_LOADING_KEY = "egosys-cart-items-loading";
export const COOKIE_PREFERENCES_KEY = "egosys-cookie-preferences";
export const CART_UI_ITEMS_KEY = "egosys-cart-ui-items";
export const CART_ORDER_ID_ITEMS_KEY = "egosys-cart-order-id-items";
export const CART_ITEMS_KEY = "egosys-cart-items";
export const TOKENS_KEY = "egosys-cart-tokens";
export const CHECKOUT_EXPIRE_KEY = "egosys-checkout-expire";
export const CUSTOMER_KEY = "egosys-customer";
export const CHECKOUT_TIMER_ID = "egosys-checkout-timer";
export const CHECKOUT_TIMER_WRAPPER_ID = "egosys-checkout-header-content-inner";
export const HEADER_TIMER = "egosys-header-timer";
export const SHIPMENT_METHOD_KEY = "egosys-shipment-method";
export const SHOP_CONFIG_CACHE_KEY = "shop-config";
export const IMPRINT_CACHE_KEY = "imprint";
export const GET_ALL_SERIES_CACHE_KEY = "get-all-series";
export const GET_SERIES_DETAILS_CACHE_KEY = "get-series-details";
export const GET_EVENT_DETAILS_CACHE_KEY = "get-event-details";
export const GET_PROFILE_CACHE_KEY = "get-profile";
export const ORDERS_KEY = "egosys-orders";
export const SERIES_EVENTS_VIEW_KEY = "series-events-view";
export const CACHE = "public, s-maxage=30, stale-while-revalidate=60";
export const PAYPAL_DATA_PARTNER_ATTRIBUTION_ID = "egocentric_Cart_PPCP";
export const DEFAULT_COOKIE_MAX_AGE = 60 * 60 * 24 * 7 * 4 * 12;
export const LOCALE_CHECK_KEY = "egosys-locale-check";
export const DEFAULT_COOKIE_EXPIRE = new Date(
  Date.now() + DEFAULT_COOKIE_MAX_AGE,
);

// Egosys Middleware Headers
export const EGOSYS_ORIGIN_HOSTNAME_HEADER = "x-egosys-origin-hostname";
export const EGOSYS_LNG_COOKIE = "x-lng";
export const EGOSYS_AUTH_TOKEN = "x-egosys-auth-token";
export const EGOSYS_URL = "x-egosys-url";
export const EGOSYS_PATHNAME = "x-egosys-pathname";
export const COOKIES_HEADER = "cookie";

// Headers
export const FORWARDED_HOST_HEADER = "x-forwarded-host";
export const HOST_HEADER = "host";
export const ACCEPT_LANGUAGE_HEADER = "accept-language";
export const CONTENT_TYPE_HEADER = "content-type";
export const FORWARDED_PROTO_HEADER = "x-forwarded-proto";

export type CartItemWithoutQuantity = {
  eventOrBundle: EventOrBundle;
  section: PlainMessage<Section>;
  series: PlainMessage<EventSeries>;
  price: PlainMessage<Price>;
  seat?: {
    id: number;
    row: string;
    name: string;
  };
  discount?: PlainMessage<DiscountConfig>["reference"];
  upgrades?: SeatUpgrade[];
};

export type CartItem = CartItemWithoutQuantity & {
  quantity: number;
};

export type Giftcards = Array<{
  id: string;
  balance: PlainMessage<Money>;
}>;

export type SeatUpgradeItem = Omit<
  PlainMessage<SeatUpgradeConfig>,
  "lineItemIds"
> & {
  lineItems: {
    id: string;
    series: {
      id: string;
      name: string;
    };
    eventOrBundle: {
      case: "event" | "bundle";
      value: {
        id: string;
        name: string;
      };
    };
    section: string;
    seat?: {
      row: string;
      name: string;
    };
  }[];
  price: PlainMessage<Money> | undefined;
};

export type GeneratePathParams =
  | {
      redirect?: string;
      orderId?: string;
      seriesId?: string;
      eventId?: string;
      bundleId?: string;
      lng?: string;
    }
  | undefined;

export type SearchParams = { [key: string]: string | string[] | undefined };

export type EventOrBundle =
  | {
      case: "event";
      value: PlainMessage<Event>;
    }
  | {
      case: "bundle";
      value: PlainMessage<Bundle> & { eventIds: string[]; seatmapId?: string };
    };

export type ReserveCancelRegister = {
  section: PlainMessage<Section>;
  price: PlainMessage<Price>;
  eventOrBundle: EventOrBundle;
  series: PlainMessage<EventSeries>;
  seat?: { id: number; row: string; name: string };
  discount?: PlainMessage<DiscountConfig>["reference"];
  upgrades?: SeatUpgrade[];
};

export type ReserveCancelRegisterSet = ReserveCancelRegister & {
  quantity: number;
};

export type SearchResult = {
  name: string;
  type: "event" | "series" | "bundle";
  url: string;
  image: PlainMessage<Image_Variant> | undefined;
};

export const AVAILABLE_LANGUAGES = ["de", "en", "fr", "hu", "it", "hr", "es"];

export type Translations = Awaited<ReturnType<typeof getDictionary>>;

export enum Month {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}
